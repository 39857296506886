
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faCirclePlus, faHistory, faHome } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const CardTitle = ({ title }) => {
    const history = useHistory();
    const handleBack = () => {
        history.goBack();
    };
    const handleHome = () => {
        history.push('/');
    };
    const handleHistory = () => {
        history.push('/orders/history');
    };
    const handleNewOrder = () => {
        history.push('/orders/new');
    };

    return (

        <div className='mb-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

            <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 300 }}
                overlay={<Tooltip>Back</Tooltip>}
            >
                <div onClick={handleBack} className='page-title-nav-button'>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />

                </div>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>New Order</Tooltip>}
            >
                <div onClick={handleNewOrder} className='page-title-nav-button'>
                    <FontAwesomeIcon icon={faCirclePlus} />

                </div>
            </OverlayTrigger>

            <h3 style={{ textAlign: 'center', margin: 0 }}>
                {title}
            </h3>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Order History</Tooltip>}
            >
                <div onClick={handleNewOrder} className='page-title-nav-button'>
                    <FontAwesomeIcon icon={faHistory} />

                </div>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Home</Tooltip>}
            >
                <div onClick={handleHome} className='page-title-nav-button'>
                    <FontAwesomeIcon icon={faHome} />

                </div>
            </OverlayTrigger>

        </div>
    );

}

export default CardTitle;