// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const amplifyconfig =  {
    API: {
        GraphQL: {
          endpoint: 'https://ujg4mcvcrnf7tox4p7rnf3pyve.appsync-api.eu-north-1.amazonaws.com/graphql',
          region: 'eu-north-1',
          defaultAuthMode: 'apiKey',
          apiKey: 'da2-lk5fssxhbzbv7lgoa32bqvvo2m'
        }
    }
};

export default amplifyconfig;