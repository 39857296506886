/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getService = /* GraphQL */ `
  query GetService($_id: ID!) {
    getService(_id: $_id) {
      name
      type
      serviceIcon
      description
      _id
      __typename
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: TableServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        type
        serviceIcon
        description
        _id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
