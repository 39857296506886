import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Badge, Row, Col } from 'react-bootstrap';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ItemCard from '../Util/ItemCard';
import StatusBadge from '../Util/StatusBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
const OrderDetails = () => {
    const { id } = useParams();

    const [order, setOrder] = useState([]);
    const history = useHistory();
    const handleEstimateClick = (partnerId) => {
        history.push(`/estimate/${partnerId}`);
    };
    useEffect(() => {
        if (id) {
            const fetchMachineData = async () => {
                try {
                    let authToken = localStorage.getItem('token');
                    const response = await axios.get(`/orders/${id}`, { headers: { Authorization: `${authToken}` } });
                    let order = response.data;

                    if (order.items === undefined) {
                        order.items = [];
                    }

                    const serviceResponse = await axios.get(`/services/${order.service_id}`, { headers: { Authorization: authToken } });
                    order.service = serviceResponse.data.service;

                    setOrder(order);
                } catch (error) {
                    console.error('API Error:', error);
                }
            };

            fetchMachineData();
        }
    }, [id]);
    return (
        <Row>
            <Col>
                <h4>Items</h4>
                <hr />
                <div className="d-grid gap-2">

                    {order.items && order.items.map((item) => (
                        <ItemCard item={item} />
                    ))}
                </div>
            </Col>
            <Col>
                <div>
                    <h4>Order Details</h4>
                    <StatusBadge status={order.status} />
                    {order.service && (
                        <Badge bg="secondary" pill className='ms-1'>
                            {order.service.name}
                        </Badge>
                    )}
                    <hr />
                    {/* Display order details here */}


                    <p>Created at: {order.orderDate}</p>

                    {order.estimationExists ? (
                        <>
                        
                        </>
                    ) : (
                        <>
                            <Button as={Link} to={`${order._id}/estimation/new`} variant="outline-primary" className='me-3'>
                                <FontAwesomeIcon icon={faPlusSquare} className='me-2'/>
                                Create Estimation

                            </Button>
                        </>
                    )

                    }


                </div>
            </Col>
        </Row>

    );
};

export default OrderDetails;
