// MachineIndex.js
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import MachineNav from './nav';
import { Route, Switch } from 'react-router-dom';
import AlertComponent from '../Util/AlertComponent';
import needAuth from '../../auth/needAuth';
import OrderTable from './table';
import OrderFind from './find';
import OrderDetails from './details';
import EstimateNew from './estimations/new';
import EstimationsIndex from './estimations';
import EstimationDetail from './estimations/detail';

const OrderIndex = () => {
    return (
        
        <Container fluid>
            <Row>
                {/* Left Card - 20% Width */}
                <Col xs={3}>
                    <MachineNav />
                </Col>

                {/* Right Card - 80% Width */}
                <Col xs={9}>
                <AlertComponent />

                    <Card className='has-shadow'>
                        <Card.Body>
                            <Switch>
                                <Route exact path="/orders" component={needAuth(OrderTable)} />
                                <Route exact path="/orders/find" component={needAuth(OrderFind)} />
                                <Route exact path="/orders/estimations" component={needAuth(EstimationsIndex)} />
                                <Route exact path="/orders/:id" component={needAuth(OrderDetails)} />
                                <Route exact path="/orders/:id/estimation/new" component={needAuth(EstimateNew)} />
                                <Route exact path="/orders/estimations/:id" component={needAuth(EstimationDetail)} />

                            </Switch>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default OrderIndex;
