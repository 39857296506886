import React, { useState } from 'react';
import { Form, Col, Button, Row, Card, InputGroup, Dropdown, Table } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { setAlert } from '../../../slices/alertSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faPaperPlane, faBan } from '@fortawesome/free-solid-svg-icons';
import ShippingAddress from '../../Util/ShippingAddress';
const EstimateNew = () => {
    const [items, setItems] = useState({});
    const [prices, setPrices] = useState([]);
    const [machines, setMachines] = useState([]);
    const { id } = useParams();
    const [machine, setMachine] = useState({});
    let user_id = localStorage.getItem('user_id');
    const authToken = localStorage.getItem('token');
    const [address,setAddress] = useState({});
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        description: '',
        price: 0,
        shippingCost: 0,
        timeEstimation: 0,
    });
    const [order, setOrder] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleAddItemPrice = (e) => {

    };

    const changePriceForItem = (e) => {
        let contains = prices.some(item =>
            item._id === e.target.name
        )
        if (contains) {
            let newPrices = prices.map(item => {
                if (item._id === e.target.name) {
                    item.price = e.target.value;
                }
                return item;
            });
            setPrices([...newPrices]);

        }
        else {
            setPrices([...prices, { [e.target.name]: e.target.value }]);

        }
        setPrices([...prices, { [e.target.name]: e.target.value }]);
        setItems({ ...items, [e.target.name]: { price: e.target.value } });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            formData.partner_id = user_id;
            formData.order_id = id;
            let authToken = localStorage.getItem("token");
            formData.machine_id = machine._id;
            formData.items = order.items.map((item) => ({ item_id: item._id, price: items[item._id].price }));
            formData.totalPrice = order.items.reduce((total, item) => {
                const itemInfo = items[item._id];
                const itemPrice = itemInfo ? itemInfo.price : 0;
                return total + itemPrice * item.quantity;
            }, 0);
            let response = await axios.post('/estimations', formData, {
                headers: {
                    Authorization: `${authToken}`,
                },
            });
            dispatch(setAlert({ message: 'Estimation submitted successfully', variant: 'success' }));
            setFormData({
                description: '',
                price: 0,
                shippingCost: 0,
                timeEstimation: 0,
            });
        } catch (error) {
            // Handle errors (e.g., show an error message)
            dispatch(setAlert({ message: error.message, variant: 'danger' }));
            console.error('Error submitting estimation:', error);
        }
    };
    const handleSelectMachine = (machine) => {
        setMachine(machine);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/orders/${id}`, { headers: { Authorization: authToken } });

              
                setAddress(response.data.address)

                setItems(response.data.items.map((item) => ({ [item._id]: { price: 0 } })));
                setOrder(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        axios.get('/partner/' + user_id + '/machines', { headers: { Authorization: `${authToken}` } })
        .then(response => {
            if (Array.isArray(response.data.machines)) {
                setMachines(response.data.machines);
                
            } else {
                console.error('API Error: Response data is not an array');
                setMachines([]);
            }
        })
        .catch(error => {
            console.error('API Error:', error);
            setMachines([]);
        });
                

        fetchData();


    }, [id, authToken]);

    return (
        <Form onSubmit={handleSubmit}>
            <h2>Create Estimation</h2>

            <div className="d-grid gap-2 mt-2">
                {order.items && order.items.map((item) => (
                    <Card key={item._id}>
                        <Card.Body>

                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            Service
                                        </th>
                                        <th>
                                            Material
                                        </th>
                                        <th>
                                            Material Option
                                        </th>
                                        <th>
                                            Machine
                                        </th>
                                        <th>
                                            Price
                                        </th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {order.service.name}
                                        </td>
                                        <td>
                                            {item.material}
                                        </td>
                                        <td>
                                            {order.service.name}
                                        </td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="outline-secondary">
                                                {machine.name ? 
                                                
                                                (<>
                                                 <img src={machine.machineIcon ? machine.machineIcon : "https://cdn4.iconfinder.com/data/icons/3d-printing-3/128/8-512.png"} alt="machine" width="30" height="30" className="d-inline-block align-text-top me-3" />
                                                    {machine.name}
                                                </>) : "Select Machine"} 
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {machines.map((machine) => (
                                                        <Dropdown.Item key={machine._id} onClick={() => handleSelectMachine(machine)}>
                                                        <img src={machine.machineIcon ? machine.machineIcon : "https://cdn4.iconfinder.com/data/icons/3d-printing-3/128/8-512.png"} alt="machine" width="30" height="30" className="d-inline-block align-text-top me-3" />
                                                            {machine.name}</Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                        <td>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>$</InputGroup.Text>
                                                <Form.Control aria-label="Amount (to the nearest dollar)" onChange={changePriceForItem} name={item._id} value={items[item._id] && items[item._id].price} />
                                                <InputGroup.Text>.00</InputGroup.Text>
                                                <Button onClick={handleAddItemPrice}>
                                                    Add
                                                </Button>
                                            </InputGroup>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                ))}
            </div>

            <hr />

            <ShippingAddress address={address} />

            <Table> 
                <thead>
                    <tr>
                        <td >
                        </td>
                        <td >
                            <strong>Item</strong>
                        </td>
                        <td>
                            <strong>Amount</strong>
                        </td>
                        <td>
                            <strong>Price</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>

                    {order.items && order.items.map((item) => (
                        <tr style={{ verticalAlign: "middle" }}>                            <td>
                            <img src={item.imageSrc} alt="item" width="70" height="70" />
                        </td>
                            <td>
                                {item.itemName}
                            </td>
                            <td>
                                {item.quantity}
                            </td>
                            <td>
                                {items[item._id] && items[item._id].price * item.quantity}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <strong>

                                Total Cost
                            </strong>
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>
                            {order.items &&
                                order.items.reduce((total, item) => {
                                    const itemInfo = items[item._id];
                                    const itemPrice = itemInfo ? itemInfo.price : 0;
                                    return total + itemPrice * item.quantity;
                                }, 0)}

                        </td>
                    </tr>
                </tbody>
            </Table>
            <div style={{float:"right"}}>
            <Button variant="primary" type="submit">
                <FontAwesomeIcon icon={faPaperPlane} className='me-2' />
                Submit
            </Button>
            <Button variant="outline-secondary`8" type="submit" className='ms-2'>
                <FontAwesomeIcon icon={faSave} className='me-2' />
                Save as Draft
            </Button>
            <Button variant="outline-danger" type="submit" className='ms-2'>
                <FontAwesomeIcon icon={faBan} className='me-2' />
                Cancel
            </Button>
            </div>

        </Form>
    );
};

export default EstimateNew;
