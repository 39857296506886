import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Badge, Container, Col } from 'react-bootstrap';
import { Link, useHistory, useParams, NavLink } from 'react-router-dom';

import { setAlert } from '../../../slices/alertSlice';
import { useDispatch } from 'react-redux';
import CardTitle from '../../Util/CardTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import ItemCard from '../../Util/ItemCard';
const EstimationDetail = () => {
    const { order_id, id } = useParams();
    const [estimation, setEstimation] = useState({});
    const [order, setOrder] = useState({});
    const [priceItems, setPriceItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [machine, setMachine] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        const authToken = localStorage.getItem('token');

        const fetchEstimationData = async () => {
            try {
                const response = await axios.get(`/estimations/${id}`, {
                    headers: { Authorization: authToken },
                });

                const priceItems = await Promise.all(
                    response.data.estimation.items.map(async (item) => {
                        const res = await axios.get(`/items/${item.item_id}`, {
                            headers: { Authorization: authToken },
                        });
                        res.data.item.price = item.price;
                        return res.data.item;
                    })
                );

                const machineResponse = await axios.get(`/machines/${response.data.estimation.machine_id}`, {
                    headers: { Authorization: authToken },
                });
                const fetchMachine = machineResponse.data.machine;

                setMachine(fetchMachine);
                setPriceItems(priceItems);
                setEstimation(response.data.estimation);
            } catch (error) {
                console.error('Error fetching estimation data:', error);
            }
        };

        const fetchOrderData = async () => {
            try {
                const response = await axios.get(`/orders/${order_id}`, {
                    headers: { Authorization: authToken },
                });

                const serviceApiResponse = await axios.get(`/services/${response.data.order.service_id}`, {
                    headers: { Authorization: authToken },
                });
                response.data.order.service = serviceApiResponse.data.service;

                setOrder(response.data.order);
            } catch (error) {
                console.error('Error fetching order data:', error);
            }
        };

        if (id) {
            fetchEstimationData();
        }

        if (order_id) {
            fetchOrderData();
        }
    }, [id, order_id]);

    const handleSelectEstimation = async () => {
        try {
            const authToken = localStorage.getItem('token');
            const user_id = localStorage.getItem('user_id');
            await axios.put(`/customer/${user_id}/orders/${estimation.order_id}`, {
                partner_id: estimation.partner_id,
                estimation_id: estimation._id,
                totalPrice: estimation.totalPrice

            }, {
                headers: { Authorization: authToken },
            });
            dispatch(setAlert('Estimation selected successfully', 'success'));
            history.push('/orders/' + estimation.order_id);
        } catch (error) {
            dispatch(setAlert('Estimation could not be selected', 'danger'));

            console.error('API Error:', error);
        }
    };

    const handleCheckout = async () => {
        setLoading(true);
        try {
            const authToken = localStorage.getItem('token');
            const user_id = localStorage.getItem('user_id');
            let payload = {
                order_id: order._id,
                estimation_id: order.estimation_id,
                success_url: 'http://localhost:3000/orders/60f9b3b3e9b3a40015f3f2b2/estimations/60f9b3b3e9b3a40015f3f2b2',
                cancel_url: 'http://localhost:3000/orders/60f9b3b3e9b3a40015f3f2b2/estimations/60f9b3b3e9b3a40015f3f2b2',
            }
            let response = await axios.post(`/payments/checkout`, payload, {
                headers: { Authorization: authToken },
            });
            if (response.data.redirect) {
                window.location.href = response.data.redirect;
            } else {
                dispatch(setAlert('Payment could not be completed', 'danger'));

            }

        } catch (error) {
            dispatch(setAlert('Payment could not be completed', 'danger'));

            console.error('API Error:', error);
        }
    };
    return (
        <Container fluid className="d-flex justify-content-center align-items-center" >
            <Col xs={6}>
                <CardTitle title="Estimation Details" />
                <div style={{ margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <Badge as={Button} variant="outline-dark" bg='dark' size="sm">{order.service && order.service.name}</Badge>

                </div>

                <p>Partner ID: {estimation.partner_id}</p>
                <p className="d-flex align-items-center">
                    Selected Machine:
                    <Badge className='ms-2 d-flex align-items-center'>
                        <img
                            style={{backgroundColor: 'white', borderRadius: '10%'}}
                            src={machine.machineIcon ? machine.machineIcon : "https://cdn4.iconfinder.com/data/icons/3d-printing-3/128/8-512.png"}
                            alt="machine"
                            width="30"
                            height="30"
                            className="d-inline-block align-text-top me-1 p-1"
                        />
                        {machine.name}
                    </Badge>
                </p>

                {priceItems && priceItems.map((item) => {
                    return (
                        <ItemCard item={item} price={true} />
                    );
                })}
                <hr />
                <p>Total Cost: {estimation.totalPrice}</p>
                <p>Created at: {new Date(estimation.created_at).toLocaleString()}</p>
                {order.partner_id == estimation.partner_id ? (
                    <>
                        <div className="d-grid gap-2">
                            <Button variant="outline-success" onClick={handleCheckout} className='m-3' type="submit" size="lg">
                                {loading ? 'Loading...' : (<><FontAwesomeIcon icon={faCreditCard} /> Checkout </>)}

                            </Button>
                        </div>
                    </>
                )
                    : (
                        <div className="d-grid gap-2">
                            <Button variant="outline-success" onClick={handleSelectEstimation} className='m-3' type="submit" size="lg">
                                <FontAwesomeIcon icon={faCheckCircle} /> Select Estimation
                            </Button>
                        </div>
                    )}



            </Col>
        </Container>
    );
};

export default EstimationDetail;
