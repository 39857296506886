// MachineTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../slices/alertSlice';
import { font } from '../../slices/alertSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const MachineTable = () => {
    const [machines, setMachines] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch(); 
    let authToken = localStorage.getItem('token');
    let user_id = localStorage.getItem('user_id');
    useEffect(() => {

        axios.get('/partner/' + user_id + '/machines', { headers: { Authorization: `${authToken}` } })
            .then(response => {
                if (Array.isArray(response.data.machines)) {
                    setMachines(response.data.machines);
                    
                } else {
                    console.error('API Error: Response data is not an array');
                    setMachines([]);
                }
            })
            .catch(error => {
                console.error('API Error:', error);
                setMachines([]);
            });
    }, []);

    const handleChangeActive = (machine) => {
        
        machine.isActive = !machine.isActive;
        axios.put('/partner/' + user_id + '/machines/' + machine._id ,machine, { headers: { Authorization: `${authToken}` } })
            .then(response => {
                setRefresh(!refresh);
                dispatch(setAlert({ message: 'Updated machine', type: 'success' }));

            })
            .catch(error => {
                console.error('API Error:', error);
                dispatch(setAlert({ message: 'Could not update the machine', type: 'danger' }));

            });
    }
    const handleArchive = (row) => {
        // Handle archive button click
        console.log('Archive:', row);
    };

    return (
        <Table hover>
            <thead>
                <tr>
                    <th>Name  <FontAwesomeIcon icon={["fa", "coffee"]} /></th>
                    <th>Type</th>
                    <th>Active</th>
                    <th>Archive</th>
                </tr>
            </thead>
            <tbody>
                {machines.map((machine) => (
                    <tr key={machine._id}>
                        <td>{machine.name}</td>
                        <td>{machine.type}</td>
                        <td>
                            <Form>
                                <Form.Check 
                                    type="switch"
                                    label={machine.isActive ? "active" : "inactive"}
                                    onChange={() => handleChangeActive(machine)}
                                    checked={machine.isActive}
                                />

                            </Form>
                        </td>
                        <td>
                            <Button as={Link} to={`machines/${machine._id}/edit`} variant="secondary" className='me-3'>
                                Edit
                            </Button>
                            <Button variant="danger" onClick={() => handleArchive(machine)}>
                                Archive
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default MachineTable;
