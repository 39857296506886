import React from 'react';
import Badge from 'react-bootstrap/Badge';

const StatusBadge = ({ status }) => {
    let bg, text;

    // Determine the variant and text based on the status
    switch (status) {
        case 'pending':
            bg = 'info';
            text = 'Pending';
            break;
        case 'in_progress':
            bg = 'primary';
            text = 'In Progress';
            break;
        case 'waiting_for_payment':
            bg = 'info';
            text = 'Waiting For Payment';
            break;
        case 'order-shipped':
            bg = 'outline-success';
            text = 'Order Shipped';
            break;
        case 'completed':
            bg = 'success';
            text = 'Completed';
            break;
        case 'canceled':
            bg = 'danger';
            text = 'Canceled';
            break;
        case 'on-hold':
            bg = 'warning';
            text = 'On Hold';
            break;
        default:
            bg = 'secondary';
            text = 'Unknown';
    }

    return (
        <Badge pill bg={bg}>
            {text}
        </Badge>
    );
};

export default StatusBadge;
