// MachineIndex.js
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import MachineNav from './nav';
import { Route, Switch } from 'react-router-dom';
import MachineNew from './new';
import MachineTable from './table';
import AlertComponent from '../Util/AlertComponent';

const MachineIndex = () => {
    return (
        
        <Container fluid>
            <Row>
                {/* Left Card - 20% Width */}
                <Col xs={3}>
                    <MachineNav />
                </Col>

                {/* Right Card - 80% Width */}
                <Col xs={9}>
                <AlertComponent />

                    <Card className='has-shadow'>
                        <Card.Body>
                            <Switch>
                                <Route exact path="/machines" component={MachineTable} />
                                <Route path="/machines/new" component={MachineNew} />
                                <Route path="/machines/:id/edit" component={MachineNew} />
                            </Switch>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default MachineIndex;
