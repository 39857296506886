
import './bootstrap.min.css';
import './App.css';

import React, { useState, useEffect} from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container} from 'react-bootstrap';
import AppNav from './components/AppNav';
import MachineIndex from './components/machines';
import OrderIndex from './components/orders';
import Login from './components/Login';
import Register from './components/Register';
import needAuth from './auth/needAuth';
import PaymentIndex from './components/payments';
import HomeIndex from './components/home/index';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import {listServices} from './graphql/queries';
import amplifyconfig from './config/aws-exports';
import Index from './components';
import MobileMessage from './components/MobileMessage';
const client = generateClient();

Amplify.configure(amplifyconfig);
function App() {
  const [services, setServices] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  async function fetchServices() {
    try {
      const todoData = await client.graphql({
        query: listServices,
      });
      const data = todoData.data.listServices.items;
      setServices(data);

    } catch (err) {

      console.log('error fetching todos');

    }

  }
  
  return (
    <Router>
      <div>
        <Container fluid style={{ marginTop: '20px', width: "70%" }}>
        <AppNav />
        <div style={{ marginTop: '20px' }}>
          <Switch>
            <Route path="/" exact component={Index} />
            {isMobile && (<>
              <Route path="*" component={MobileMessage} />
            </>)}

            {!isMobile && (<>
              <Route path="/login" exact component={Login} />
            <Route path="/home" exact component={needAuth(HomeIndex)} />
            <Route path="/register" exact component={Register} />
            <Route path="/machines" component={needAuth(MachineIndex)} />
            <Route path="/orders" component={needAuth(OrderIndex)} />
            <Route path="/payments" component={needAuth(PaymentIndex)} />
            </>)}


          </Switch>
          </div>
        </Container>
      </div>

    </Router>
  );
}

export default App;
