// MachineTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';

import timeAgo from '../../util/timeago';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import StatusBadge from '../Util/StatusBadge';
const OrderFind = () => {
    const [orders, setOrders] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch(); 
    let authToken = localStorage.getItem('token');
    let user_id = localStorage.getItem('user_id');
    useEffect(() => {
        axios.get('/orders', { headers: { Authorization: authToken } })
          .then(response => {
            if (Array.isArray(response.data.orders)) {
              // Use Promise.all to wait for all service requests to complete
              const servicePromises = response.data.orders.map(o => {
                if (o.service_id) {
                  return axios.get(`/services/${o.service_id}`, { headers: { Authorization: authToken } })
                    .then(response => response.data.service)
                    .catch(error => {
                      console.error('API Error:', error);
                      return null; // Handle the error gracefully, return null or a default value
                    });
                } else {
                  return null;
                }
              });
    
              Promise.all(servicePromises)
                .then(services => {
                  // Replace the service promise with the actual service data in orders
                  const updatedOrders = response.data.orders.map((o, index) => {
                    if (o.service_id) {
                      o.service = services[index];
                    }
                    return o;
                  });
    
                  setOrders(updatedOrders);
                })
                .catch(error => {
                  console.error('Promise.all Error:', error);
                  setOrders([]);
                });
            } else {
              console.error('API Error: Response data is not an array');
              setOrders([]);
            }
          })
          .catch(error => {
            console.error('API Error:', error);
            setOrders([]);
          });
      }, [authToken]); // Add any dependencies that should trigger the effect when changed
    

    const handleArchive = (row) => {
        // Handle archive button click
        console.log('Archive:', row);
    };


    return (
        <Table hover>
            <thead>
                <tr>
                    <th></th>
                    <th>Order ID</th>
                    <th>Country</th>
                    <th>Status</th>
                    <th>Service</th>
                    <th>items</th>
                    <th>Created at</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {orders.map((order) => (
                    <tr key={order._id}>
                        <td>
                            <img src={order.service.serviceIcon} className='' alt={order.service.name} width="30" height="30" />
                            </td>
                        <td>
                          <Link to={`/orders/${order._id}`}>
                          {order._id}

                            </Link>
                          </td>
                        <td>{order.country}</td>
                        <td><StatusBadge status={order.status} /></td>
                        <td>{order.service.name}</td>
                        <td>{order.items.length}</td>

                        <td>{timeAgo(order.orderDate)}</td>

                        <td>
                            <Button  as={Link} to={`${order._id}`} variant="outline-primary" className='me-3'>
                                <FontAwesomeIcon icon={faEye} className='me-2' />
                                View
                            </Button>
                 
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default OrderFind;
