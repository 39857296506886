// MachineNew.js
import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const MachineNew = () => {
  const [machineData, setMachineData] = useState({
    name: '',
    user_id: '',
    type: '',
    brand: '',
    model: '',
    serial_number: '',
    description: '',
    printingSpeed: '',
    supportedMaterials: '',
    buildVolume: '',
    resolution: '',
    maintenanceSchedule: '',
    maintenanceCost: '',
    powerConsumptionRate: '',
    setupCost: '',
    postProcessingCost: '',
    isAvailable: false,
  });
  const { id } = useParams(); 
  useEffect(() => {
    if (id) {
      const fetchMachineData = async () => {
        try {
          let authToken = localStorage.getItem('token');

          const response = await axios.get(`/machines/${id}`,{headers: { Authorization: `${authToken}` }});
          setMachineData(response.data.machine);
        } catch (error) {
          console.error('API Error:', error);
        }
      };

      fetchMachineData();
    }
  }, [id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMachineData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let authToken = localStorage.getItem('token');
    try {
      if (id) {
        // Update existing machine
        await axios.put(`/machines/${id}`, machineData, {
          headers: {
            Authorization: `${authToken}`,
          },
        });
      } 
      else {
        // Add new machine
        await axios.post('/machines', machineData, {
          headers: {
            Authorization: `${authToken}`,
          },
        });
      }

      setMachineData({
        name: '',
        type: '',
      });
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  return (
    <div>
      <h2>Add/Edit Machine</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="name">
              <Form.Label>Name <span style={{ color: 'red' }}>*</span>:</Form.Label>
              <Form.Control
                required
                type="text"
                name="name"
                value={machineData.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={machineData.description}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="type">
              <Form.Label>Type <span style={{ color: 'red' }}>*</span>:</Form.Label>
              <Form.Control
                required
                type="text"
                name="type"
                value={machineData.type}
                onChange={handleChange}
              />
            </Form.Group>

          </Col>
        </Row>
        <hr />
        Machine Details
        <Row>
          <Col>
            <Form.Group controlId="brand">
              <Form.Label>Brand:</Form.Label>
              <Form.Control
                type="text"
                name="brand"
                value={machineData.brand}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="serial_number">
              <Form.Label>Model:</Form.Label>
              <Form.Control
                type="text"
                name="model"
                value={machineData.Model}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="serial_number">
              <Form.Label>Serial Number:</Form.Label>
              <Form.Control
                type="text"
                name="serial_number"
                value={machineData.serial_number}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        Machine Capabilities
        <Row>
          <Col>
            <Form.Group controlId="supportedMaterials">
              <Form.Label>Supported Materials:</Form.Label>
              <Form.Control
                type="text"
                name="supportedMaterials"
                value={machineData.supportedMaterials}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="buildVolume">
              <Form.Label>Build Volume:</Form.Label>
              <Form.Control
                type="text"
                name="buildVolume"
                value={machineData.buildVolume}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="printingSpeed">
              <Form.Label>Printing Speed:</Form.Label>
              <Form.Control
                type="text"
                name="printingSpeed"
                value={machineData.printingSpeed}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="printingSpeed">
              <Form.Label>Printing resolution:</Form.Label>
              <Form.Control
                type="text"
                name="resolution"
                value={machineData.resolution}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>


        <Button variant="primary" type="submit">
          Save Machine
        </Button>
      </Form>
    </div>
  );
};

export default MachineNew;
