// MachineIndex.js
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import AlertComponent from '../Util/AlertComponent';
import needAuth from '../../auth/needAuth';
import PaymentConnectButton from './PaymentConnectButton';

const PaymentIndex = () => {
    return (
        
        <Container fluid>
            <Row>
                {/* Left Card - 20% Width */}
                <Col xs={3}>
                </Col>

                {/* Right Card - 80% Width */}
                <Col xs={9}>
                <AlertComponent />

                    <Card className='has-shadow'>
                        <Card.Body>
                            <Switch>
                                <PaymentConnectButton />
                            </Switch>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default PaymentIndex;
