import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthenticated, selectAuthenticated } from '../slices/authSlice';
import makershiplogo from './makership-logo-1000.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faHome, faListUl, faSignOut, faHardDrive, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
const AppNavbar = () => {
    // Check if the token is set in localStorage
    const isAuthenticated = useSelector(selectAuthenticated);
    const dispatch = useDispatch();
    const history = useHistory(); // Get the history object
    const handleLogout = () => {
        localStorage.removeItem('token');
        dispatch(setAuthenticated(false));
        history.push('/');

    };

    return (
        <Navbar className='app-navbar has-shadow' variant="light">
            <Navbar.Brand href="/">
                <img src={makershiplogo} width="40" height="40" className="d-inline-block align-top ms-2" alt="Maker ship logo" />
            </Navbar.Brand>

            {isAuthenticated ? (
                <Container>

                    <Nav className="mr-auto">
                        <Nav.Link
                            as={NavLink}
                            exact
                            to="/home"
                            className={({ isActive, isPending }) =>
                                isPending ? 'pending' : isActive ? 'active' : ''
                            }
                        >
                            <FontAwesomeIcon icon={faHome} className='me-2' />
                            Home
                        </Nav.Link>
                        <Nav.Link
                            as={NavLink}

                            to="/machines"
                            className={({ isActive, isPending }) =>
                                isPending ? 'pending' : isActive ? 'active' : ''
                            }
                        >
                            <FontAwesomeIcon icon={faHardDrive} className='me-2' />

                            Machines
                        </Nav.Link>
                        <Nav.Link
                            as={NavLink}

                            to="/orders"
                            className={({ isActive, isPending }) =>
                                isPending ? 'pending' : isActive ? 'active' : ''
                            }
                        >
                            <FontAwesomeIcon icon={faListUl} className='me-2' />

                            Orders
                        </Nav.Link>
                        <Nav.Link
                            as={NavLink}

                            to="/payments"
                            className={({ isActive, isPending }) =>
                                isPending ? 'pending' : isActive ? 'active' : ''
                            }
                        >
                        <FontAwesomeIcon icon={faMoneyBillWave} className='me-2' />

                            Payments
                        </Nav.Link>

                    </Nav>
                    <Nav>
                        <Button variant="outline-info" onClick={handleLogout}>
                        <FontAwesomeIcon icon={faSignOut} className='me-2' />

                            Logout
                        </Button>
                    </Nav>
                </Container>

            ) : (
                <Container>
                    <Nav>

                    </Nav>
                    <Nav>
                        <Nav.Link
                            as={NavLink}
                            exact
                            to="/login"
                            className={({ isActive, isPending }) =>
                                isPending ? 'pending' : isActive ? 'active' : ''
                            }
                        >
                            Login
                        </Nav.Link>
                        <Nav.Link
                            as={NavLink}
                            exact
                            to="/register"
                            className={({ isActive, isPending }) =>
                                isPending ? 'pending' : isActive ? 'active' : ''
                            }
                        >
                            Register
                        </Nav.Link>
                    </Nav>
                </Container>

            )}



        </Navbar>
    );
};

export default AppNavbar;
