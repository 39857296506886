// MachineIndex.js
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const MachineNav = () => {
    return (
        <ListGroup className='has-shadow'>
            <ListGroup.Item as={NavLink} exact  to="/machines" className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
            }>
                Manage Machines
            </ListGroup.Item>
            <ListGroup.Item as={NavLink} exact  to="/machines/new" className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
            }>
                Add Machine
            </ListGroup.Item>
        </ListGroup>
    );
};

export default MachineNav;
