// MachineTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../slices/alertSlice';
import { font } from '../../slices/alertSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders, faWrench, faArchive } from '@fortawesome/free-solid-svg-icons';
import timeAgo from '../../util/timeago';
import StatusBadge from '../Util/StatusBadge';
const OrderTable = () => {
    const [orders, setOrders] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch(); 
    let authToken = localStorage.getItem('token');
    let user_id = localStorage.getItem('user_id');
    useEffect(() => {

        axios.get('/partner/' + user_id + '/orders', { headers: { Authorization: `${authToken}` } })
            .then(response => {
                if (Array.isArray(response.data.orders)) {
                    setOrders(response.data.orders);
                    
                } else {
                    console.error('API Error: Response data is not an array');
                    setOrders([]);
                }
            })
            .catch(error => {
                console.error('API Error:', error);
                setOrders([]);
            });
    }, []);

    const handleArchive = (row) => {
        // Handle archive button click
        console.log('Archive:', row);
    };


    return (
        <Table hover>
            <thead>
                <tr>
                    <th>Order ID</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Created at</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {orders.map((order) => (
                    <tr key={order._id}>
                        <td>{order._id}</td>
                        <td><StatusBadge status={order.status}/></td>
                        <td>{order.totalAmount}</td>
                        <td>{timeAgo(order.orderDate)}</td>

                        <td>
                            <Button  as={Link} to={`machines/${order._id}/edit`} variant="outline-info" className='me-3'>
                                <FontAwesomeIcon icon={faSliders} />
                            </Button>
                            <Button variant="outline-danger" onClick={() => handleArchive(order)}>
                            <FontAwesomeIcon icon={faArchive} />

                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default OrderTable;
