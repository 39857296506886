// withAuth.js
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const needAuth = (WrappedComponent) => {
  const NeedAuth = (props) => {
    const history = useHistory();

    useEffect(() => {
      const token = localStorage.getItem('token');

      if (!token) {
        history.push('/login');
      }
    }, [history]);

    return <WrappedComponent {...props} />;
  };

  return NeedAuth;
};

export default needAuth;