import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import axios from 'axios';

const PaymentConnectButton = () => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);

      // Make a POST request to /payments/connect using Axios
      const response = await axios.post('/payments/connect', {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        // You can add a request body if needed
        // data: { key: 'value' },
      });

      if (response.data.redirect) {
        // Redirect to the provided URL
        window.location.href = response.data.redirect;
      }
    } catch (error) {
      console.error('Failed to connect payment:', error.message);
      // Handle other types of errors if needed
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button variant="primary" onClick={handleClick} disabled={loading}>
      {loading ? (
        <>
          <Spinner animation="border" size="sm" />
          {' Connecting...'}
        </>
      ) : (
        'Connect Payment'
      )}
    </Button>
  );
};

export default PaymentConnectButton;
