import { Card } from 'react-bootstrap';

function NewsCard({ title, body, date }) {
  return (
    <Card className='mt-3 glass' style={{border: "none"}}>

      <Card.Body>
      <Card.Title className=''>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <strong className="me-auto">{title}</strong>
          <small>11 mins ago</small>
        </div>

      </Card.Title>
        {body}</Card.Body>
    </Card>
  );
}

export default NewsCard;