// MachineIndex.js
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const orderNav = () => {
    return (
        <ListGroup className='has-shadow '>
            <ListGroup.Item as={NavLink} exact  to="/orders" className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
            }>
                Manage Orders
            </ListGroup.Item>
            <ListGroup.Item as={NavLink} exact  to="/orders/find" className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
            }>
                Find Orders
            </ListGroup.Item>
            <ListGroup.Item as={NavLink} exact  to="/orders/estimations" className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
            }>
                Estimates
            </ListGroup.Item>
  
            <ListGroup.Item as={NavLink} exact  to="/orders/history" className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
            }>
                Order History
            </ListGroup.Item>
  
        </ListGroup>
    );
};

export default orderNav;
