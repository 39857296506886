// MobileMessage.js
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';

const MobileMessage = ({ children }) => {

  return (
    
    <Card>
        <Card.Body>
            <Card.Text>
            <h1 className='text-center'><FontAwesomeIcon icon={faDesktop} /></h1>
                <h1 className='text-center'>Mobile Not Supported</h1>
                <p className='text-center'>This app is made for desktop only. Please use a desktop to access this app.</p>
         

            </Card.Text>
        </Card.Body>
    </Card>
  )
};

export default MobileMessage;
