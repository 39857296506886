// AlertComponent.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearAlert } from '../../slices/alertSlice';
import { Alert, Button } from 'react-bootstrap';

const AlertComponent = () => {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearAlert());
  };

  useEffect(() => {
    let timeoutId;

    if (alert.message) {
      timeoutId = setTimeout(() => {
        dispatch(clearAlert());
      }, 5000); // Set the timeout duration (in milliseconds)
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [alert, dispatch]);

  return (
    <div>
      {alert.message && (
        <Alert variant={alert.type} show={!!alert.message} onClose={handleClose} dismissible>
          {alert.message}
   
        </Alert>
      )}
    </div>
  );
};

export default AlertComponent;
