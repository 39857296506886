import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import NewsCard from '../Util/NewsCard';
import OnboardingChecklist from './checklist';
import axios from 'axios';
const HomeIndex = () => {
    const onboarding_completed = localStorage.getItem('onboarding_completed');
    const [onboardingProgress, setOnboardingProgress] = useState({});
  
    const fetchOnboardingProgress = async () => {
      try {
        const user_id = localStorage.getItem('user_id');
        const response = await axios.get('/partner/' + user_id + '/onboarding', {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        return response.data; 
      } catch (error) {
        console.error(error);
        throw error; 
      }
    };
  
    useEffect(() => {
      const fetchData = async () => {
        if (!onboarding_completed) {
          try {
            const data = await fetchOnboardingProgress();
            setOnboardingProgress(data);
            console.log(data);
          } catch (error) {
          }
        }
      };
  
      fetchData();
    }, [onboarding_completed]); 

    return (
        <Container fluid className="d-flex justify-content-center align-items-center" style={{}}>
            <Row>
            <div className='mt-4 mb-4'>
                {!onboarding_completed  && <OnboardingChecklist progress={onboardingProgress}/> }
                 
                </div>
                <Col>
                    <Card className='glass gradient-background' >
                        <Card.Body>
                            <Card.Title>Pending</Card.Title>
                            <h1 className='ms-3 text-info'>
                                2
                            </h1>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='glass gradient-background' >
                        <Card.Body>
                            <Card.Title>Active Orders</Card.Title>
                            <h1 className='ms-3 text-info'>
                                1
                            </h1>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='glass gradient-background' >
                        <Card.Body>
                            <Card.Title>Fulfilled Orders</Card.Title>
                            <h1 className='ms-3 text-info'>
                                1
                            </h1>
                        </Card.Body>
                    </Card>
                </Col>
                <div className='mt-4'>
                    <h3>News</h3>
                    <NewsCard title={"Beta Testing"} body="We are currently in beta testing. Please report any bugs to support@makership.io" />
                    <NewsCard title={"New Features"} body="New features for order creation and reviewing the orders are coming, have any feature request your self? " />

                </div>

            </Row>
        </Container>

    )

}

export default HomeIndex;   