import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { setAlert } from '../slices/alertSlice';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AlertComponent from './Util/AlertComponent';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      formData.role = 'partner'
      axios.post("/user/register", formData)
      dispatch(setAlert({ message: 'Create your account, please login', type: 'success' }));
      setTimeout(() => {
        history.push('/login');
      }, 1000);
    } catch (error) {
      dispatch(setAlert({ message: 'Problem', type: 'danger' }));
      console.error('Login failed:', error);
    }

  };

  return (

    <Row>
      <Col>
      </Col>
      <Col md={8}>
        <AlertComponent />
        <Card className='has-shadow'>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="username">
                <Form.Label>Username:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password:</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </Form.Group>

              <Button className='m-3' variant="primary" type="submit">
                Register
              </Button>
                   
            </Form>
            
            <p style={{textAlign: "center"}}>Already have an account? Login <Link to="/login">here</Link></p> 

          </Card.Body>
        </Card>
      </Col>
      <Col>
      </Col>
    </Row>

  );
};

export default Register;
