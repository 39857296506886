import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import AlertComponent from './Util/AlertComponent';
import { useDispatch,useSelector } from 'react-redux';
import { setAuthenticated, selectAuthenticated } from  '../slices/authSlice';
import { setAlert } from '../slices/alertSlice';
import { useHistory } from 'react-router-dom';
const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isAuthenticated = useSelector(selectAuthenticated);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    if(isAuthenticated){
        history.push("/home")
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleLogin = async () => {
        try {
          const response = await axios.post('/user/login', formData);
          const token = response.data.token;
          const user_id = response.data.user_id;
          const onboarding_completed = response.data.onboarding_completed;

          localStorage.setItem('token', token);
          localStorage.setItem('user_id', user_id);
          localStorage.setItem('completed_onboarding', onboarding_completed);

          dispatch(setAuthenticated(true));   

          setTimeout(() => {
            history.push('/login');
          }, 1000);
        } catch (error) {
          dispatch(setAlert({ message: 'Problem', type: 'danger' }));
          console.error('Login failed:', error);
        }
      };
      
    const handleSubmit = (e) => {
        e.preventDefault();
        handleLogin();
    };

    return (
        <Row>
            <Col md={2}>
            </Col>
            <Col md={8}>
            <AlertComponent />
                <Card className='has-shadow'>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="email" className='m-3'>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="password" className='m-3'>
                                <Form.Label>Password:</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Button className='m-3' variant="primary" type="submit">
                                Login
                            </Button>
                        </Form>
                    </Card.Body>

                </Card>

            </Col>
            <Col>
            </Col>
        </Row>

    );
};

export default Login;
