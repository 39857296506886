import React from 'react';
import { Row, Col, InputGroup, FormControl } from 'react-bootstrap';

const ShippingAddress = ({ address }) => {
    return (
        <div className="shipping-address">
            <h5>Shipping</h5>
            <Row>
                {/* Address Section */}
                <Col>
                    <Row>
                        <Col md={6}>
                            <p><strong>Street: </strong>{address.street}</p>
                            <p><strong>City: </strong>{address.city}</p>
                        </Col>
                        <Col md={6}>
                            <div className="customer-address">

                                <p><strong>Country: </strong>{address.country}</p>
                                <p><strong>Zip Code: </strong>{address.zipCode}</p>
                            </div>
                        </Col>
                    </Row>

                </Col>


                {/* Delivery Company and Price Section */}
                <Col md={6}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="deliveryCompany">Delivery Company</InputGroup.Text>
                        <FormControl
                            aria-describedby="deliveryCompany"
                            placeholder="Enter delivery company"
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="price">Price</InputGroup.Text>
                        <FormControl
                            aria-describedby="price"
                            placeholder="Enter price"
                        />
                    </InputGroup>
                </Col>
            </Row>
        </div>
    );
};

export default ShippingAddress;
